<template>
    <div>
        <!-- S: 검색 -->
        <div class="ml-auto mb-16px mb-md-24px" :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 500px' : ''">
            <v-row align="center" class="row--xxs">
                <v-col cols="12" sm="auto">
                    <v-select v-model="filter.type" v-bind="{ ...attrs_input }" clearable :items="typeItems" item-text="text" item-value="value" placeholder="전체" class="v-input--density-compact w-sm-180px" />
                </v-col>
                <v-col cols="12" sm="auto">
                    <v-select v-model="filter.searchKey" v-bind="{ ...attrs_input }" clearable :items="searchKeys" item-text="text" item-value="value" placeholder="전체" class="v-input--density-compact w-sm-180px" />
                </v-col>
                <v-col cols="12" sm="">
                    <v-text-field v-model="filter.searchValue" v-bind="{ ...attrs_input }" placeholder="검색어를 입력하세요" class="v-input--density-compact" @keydown.enter="getQuestions" />
                </v-col>
                <v-col cols="12" sm="auto">
                    <v-btn v-bind="{ ...btn_primary3 }" class="v-btn--input-compact w-100 w-sm-auto" @click="getQuestions">검색</v-btn>
                </v-col>
            </v-row>
        </div>
        <!-- E: 검색 -->

        <!-- S: 게시판 -->
        <v-data-table v-bind="{ ...table_primary }" :headers="headers" :items="items" no-data-text="작성된 문의사항이 없습니다" mobile-breakpoint="0" class="v-data-table--mobile v-data-table--board table-fixed" @click:row="(any, { item }) => showBoard(item)">
            <template #[`item.subject`]="{ item }">
                <div data-idx="5" class="text-left text-truncate">
                    {{ item.subject }}
                </div>
            </template>
            <template #[`item.type`]="{ item }">
                <span>{{ displayType(item.type) }}</span>
            </template>
            <template #[`item.createdAt`]="{ item }">
                <span>{{ item.createdAt.toDate() }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
                <span v-if="item.reply" class="primary--text">답변완료</span>
                <span v-else class="grey--text text--lighten-1">답변대기</span>
            </template>
        </v-data-table>
        <!-- E: 게시판 -->

        <!-- S: 버튼 -->
        <div class="btn-wrap btn-wrap--sm">
            <v-row justify="end" class="row--xs">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary }" :to="{ query: { _question: null } }">작성하기</v-btn>
                </v-col>
            </v-row>
        </div>
        <!-- E: 버튼 -->

        <!-- S: 페이징 -->
        <div class="pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search" />
        </div>
        <!-- E: 페이징 -->
    </div>
</template>

<script>
import { attrs_input, btn_primary, btn_primary3, table_primary } from "@/assets/variables";

import PaginationComponent from "../../../pagination-component.vue";

const headers = [
    { width: "68%", align: "center", cellClass: "v-data-table--board__tit", text: "제목", value: "subject" },
    { width: "15%", align: "center", text: "문의유형", value: "type" },
    { width: "16%", align: "center", text: "작성일", value: "createdAt" },
    { width: "16%", align: "center", text: "상태", value: "actions" },
];

export default {
    components: {
        PaginationComponent,
    },
    props: {
        code: { type: String },
    },
    data: () => ({
        attrs_input,
        btn_primary,
        btn_primary3,
        table_primary,

        filter: {
            searchKey: null,
            searchValue: null,
            type: null,
        },

        page: 1,
        pageCount: 0,
        limit: 10,

        questions: [],
        headers,
        searchKeys: [
            { text: "전체", vlaue: null },
            { text: "제목", vlaue: "subject" },
            { text: "제목 + 내용", vlaue: "subjectcontent" },
        ],

        typeItems: [
            { text: "전체", value: null },
            { text: "발송 문의", value: "SEND" },
            { text: "결제 문의", value: "PAY" },
            { text: "취소/환불", value: "CANCEL" },
            { text: "기타", value: "ETC" },
        ],
        
    }),
    computed: {
        items() {
            return [...this.questions];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getQuestions();

                this.$nextTick(() => {
                    // 문의내역 - 답변 토글
                    (function () {
                        let question = document.querySelectorAll('.board-table--inquiry [role="button"]'),
                            answer = document.querySelectorAll('.board-table--inquiry [role="rowgroup"].answer');

                        for (let i = 0; i < question.length; i++) question[i].addEventListener("click", toggleInquiry);

                        function toggleInquiry() {
                            let idx = this.getAttribute("data-idx"),
                                toggle = document.querySelector('.board-table--inquiry [role="rowgroup"][data-idx-answer="' + idx + '"]');

                            if (toggle !== null) {
                                if (toggle.getAttribute("data-answer") === "opened") {
                                    for (let i = 0; i < answer.length; i++) answer[i].removeAttribute("data-answer");
                                } else {
                                    for (let i = 0; i < answer.length; i++) answer[i].removeAttribute("data-answer");
                                    toggle.setAttribute("data-answer", "opened");
                                }
                            }
                        }
                    })();
                });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        async search() {
            try {
                await this.getQuestions();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getQuestions() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/me/center/questions", {
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit,
                        },
                        params: { ...this.filter, code: this.code },
                    });

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.questions = res.data.questions;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
        showBoard(item) {
            this.$router.push({ query: { _question: item._id } });
        },

        displayType(type) {
            const questionMap = {
                SEND: "발송 문의",
                PAY: "결제 문의",
                CANCEL: "취소/환불",
                ETC: "기타"
            }
            return questionMap[type]
        },
    },
};
</script>
