var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "ml-auto mb-16px mb-md-24px",
    style: _vm.$vuetify.breakpoint.mdAndUp ? 'max-width: 500px' : ''
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact w-sm-180px",
    attrs: {
      "clearable": "",
      "items": _vm.typeItems,
      "item-text": "text",
      "item-value": "value",
      "placeholder": "전체"
    },
    model: {
      value: _vm.filter.type,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "type", $$v);
      },
      expression: "filter.type"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact w-sm-180px",
    attrs: {
      "clearable": "",
      "items": _vm.searchKeys,
      "item-text": "text",
      "item-value": "value",
      "placeholder": "전체"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "placeholder": "검색어를 입력하세요"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getQuestions.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--input-compact w-100 w-sm-auto",
    on: {
      "click": _vm.getQuestions
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary3), false), [_vm._v("검색")])], 1)], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--mobile v-data-table--board table-fixed",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "no-data-text": "작성된 문의사항이 없습니다",
      "mobile-breakpoint": "0"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.subject`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-left text-truncate",
          attrs: {
            "data-idx": "5"
          }
        }, [_vm._v(" " + _vm._s(item.subject) + " ")])];
      }
    }, {
      key: `item.type`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(_vm.displayType(item.type)))])];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(item.createdAt.toDate()))])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.reply ? _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("답변완료")]) : _c('span', {
          staticClass: "grey--text text--lighten-1"
        }, [_vm._v("답변대기")])];
      }
    }], null, true)
  }, 'v-data-table', Object.assign({}, _vm.table_primary), false)), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": {
        query: {
          _question: null
        }
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("작성하기")])], 1)], 1)], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }